<!-- Hidden audio player -->
<audio #audioPlayer hidden></audio>
<ui-static-page-layout
  *ngrxLet="quizPageVm$; let studysetVm"
  showBackButton
  [title]="studysetVm.studyset ? ('Oral Test' | translate: { subjectName: studysetVm.studyset.name }) : ''"
  (back)="studysetVm.studyset?.id ? goBack(studysetVm.studyset?.id) : goBack()"
>
  @if (isMobile) {
    <ui-ai-assistant-avatar slot="header-start" [size]="avatarSize()" [presetState]="avatarPresetState()" />
  }
  <!-- Mute button in the header -->
  <button slot="header-end" uiIconButton tertiary (click)="toggleMute()">
    <i [class]="isMuted ? 'app-icon-mute' : 'app-icon-volume'"></i>
  </button>

  <div slot="content" class="oral-quiz-content">
    <div class="container">
      <div class="progress-bar">
        <ui-progress-bar [progress]="(vm.questionIndex() / questionSetTotalCount()) * 100" />
        <label class="progress-label">{{ vm.questionIndex() + "/" + questionSetTotalCount() }} </label>
      </div>

      <div #scrollContainer class="scroll-container" (scroll)="onScroll($event)">
        <!-- Display only the last assistant message -->
        <ui-speech-bubble
          *ngIf="!vm.isGeneratingResponse() && !lastUserMessage() && lastAssistantMessage() as assistantMessage"
          [position]="isMobile ? 'right' : 'top'"
          [alignment]="isMobile ? 'start' : 'center'"
        >
          <div [innerHTML]="assistantMessage.text | markdownToHtml"></div>
        </ui-speech-bubble>
        <!-- Centered AI Assistant Avatar -->
        @if (!isMobile) {
          <div class="avatar-container">
            <ui-ai-assistant-avatar class="onboarding-assistant-sparkles" [size]="avatarSize()" [presetState]="avatarPresetState()" />
          </div>
        }

        @switch (tutorState()) {
          @case (TutorStateEnum.LISTENING) {
            <div *ngIf="!isMobile" class="user-speaking">{{ "ORAL_QUIZ.PAGE.TUTOR_STATE.LISTENING" | translate }}</div>
          }
          @case (TutorStateEnum.THINKING) {
            <div *ngIf="!isMobile" class="user-speaking">{{ "ORAL_QUIZ.PAGE.TUTOR_STATE.THINKING" | translate }}</div>
          }
        }

        <ui-chat-message *ngIf="lastUserMessage() as userMessage" isUserMessage [text]="userMessage.text" [@fadeInOut] />
      </div>

      <footer>
        @if (vm.userOptions().length > 0 && !tutorIsSpeaking()) {
          <div class="user-options">
            @for (option of vm.userOptions(); track option) {
              <button uiButton secondary size="sm" [@fadeInOut] (click)="onOptionClick(option)">
                <span>{{ option }}</span>
              </button>
            }
          </div>
        }

        <div class="userButton">
          @switch (userButtonState()) {
            @case ("stopRecording") {
              <button uiResponsiveButton destructive (click)="toggleRecording()">
                <i slot="icon" class="app-icon-stop-circle"></i>
                {{ "ORAL_QUIZ.PAGE.STOP_BUTTON" | translate }}
              </button>
            }
            @case ("processing") {
              <button uiButton secondary [attr.aria-label]="'Click to interrupt'" (click)="onInterrupt()">
                <i slot="icon" class="app-icon-progress"></i>
                {{ "ORAL_QUIZ.PAGE.PROCESSING_BUTTON" | translate }}
              </button>
            }
            @default {
              <button
                uiResponsiveButton
                [aiTheme]="true"
                [attr.aria-label]="'ORAL_QUIZ.PAGE.RECORD_BUTTON' | translate"
                [disabled]="hasReachedPremiumLimit()"
                (click)="toggleRecording()"
              >
                <i slot="icon" class="app-icon-microphone"></i>
                {{ "ORAL_QUIZ.PAGE.RECORD_BUTTON" | translate }}
              </button>
            }
          }
        </div>
      </footer>
    </div>
  </div>
</ui-static-page-layout>
