import { Inject, Injectable } from '@angular/core';

import { ANALYTICS_SERVICE, AnalyticsBaseService, ShareDialogOpenSource } from '@stsm/analytics';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { SharingDialogComponent } from '../components/sharing-dialog/sharing-dialog.component';

@Injectable({ providedIn: 'root' })
export class SharingDialogService {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _platformModalService: PlatformModalService,
  ) {}

  openSharingDialog(source: ShareDialogOpenSource): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_open',
      properties: { source },
    });
    this._platformModalService.create({
      component: SharingDialogComponent,
      data: { source },
      webOptions: {
        maxWidth: '800px',
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }
}
