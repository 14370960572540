import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { isNil } from 'lodash-es';

import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { PremiumLimitsService } from '@stsm/premium/services/premium-limits.service';
import { PremiumModalService } from '@stsm/premium/services/premium-modal.service';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/tokes/premium-modal-service.token';
import { Tab } from '@stsm/shared/enums/tab';

declare global {
  interface Window {
    paypal?: unknown;
  }
}

export const oralQuizPagePremiumGuard: CanActivateFn = (): boolean => {
  const premiumModalService: PremiumModalService = inject(PREMIUM_MODAL_SERVICE);
  const premiumLimitsService: PremiumLimitsService = inject(PremiumLimitsService);
  const navigationService = inject(NAVIGATION_SERVICE);
  const document = inject(DOCUMENT);

  const showPremiumModal = (): void => {
    premiumModalService.openPremiumModal({
      source: 'oral-quiz',
    });
  };

  const hasReachedLimit = premiumLimitsService.hasReachedOralQuizModePremiumLimit();

  if (!hasReachedLimit) {
    return true;
  }

  // When the quiz mode is directly accessed via URL, we need to navigate to the home tab first as the PayPal plugin is not yet loaded.
  if (isNil(document?.defaultView?.paypal)) {
    void navigationService.navigateToTab(Tab.HOME).then(() => {
      showPremiumModal();
    });
  } else {
    showPremiumModal();
  }

  return false;
};
