<app-flashcard-result-presentation
  [buttonTranslationKey]="'GLOBAL.CONTINUE'"
  [closeTranslationKey]="'GLOBAL.CLOSE'"
  [confirmButtonHasPremiumLock]="hasReachedPremiumLimit()"
  (buttonClick)="onButtonClick()"
  (closeRequest)="onClose()"
>
  <div class="inner-content-container">
    <div class="header">
      <h2>{{ "ORAL_QUIZ.PAGE.EVALUATION.HEADER" | translate }}</h2>
    </div>

    <!-- progress bar -->
    <div class="progress-container">
      <ui-circle-progressbar [progress]="percentageScore">
        <div class="progress-primary-text">{{ percentageScore }}%</div>
      </ui-circle-progressbar>
    </div>

    <div [innerHTML]="evaluation | markdownToHtml"></div>

    <button class="feedback-button" uiButton tertiary accent size="sm" (click)="onClickGiveFeedback()">
      <i class="app-icon-feedback" slot="start"></i>
      {{ "SMART_EXAM.GIVE_FEEDBACK" | translate }}
    </button>

    <ui-confetti-canvas />
  </div>
</app-flashcard-result-presentation>
