<div class="navigation-menu" [attr.data-cy]="'navigation-menu'">
  @for (item of visibleNavigationRoutes(); track item.path) {
    <a [attr.data-cy]="'tabs-button-' + [item.path]" (click)="navItemClicked.emit(item.path)">
      <ui-toolbar-item
        [icon]="!currentTab() || currentTab() === item.path ? item.data.appIconActive : item.data.appIcon"
        [label]="item.data.textTranslationKey | translate"
        [isActive]="currentTab() ? currentTab() === item.path : !isFullPageRouteActive()"
        [showUnseenIndicator]="indicators()?.[item.path]"
        [flat]="true"
        [inMenu]="true"
      />
    </a>
  }
</div>
