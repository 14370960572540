import { ChatCompletionTool } from '@stsm/global/models/ai/chat-gpt.interface';

export interface QuestionsSet {
  questionSetId: string;
  numberOfQuestions: number;
  questions: string[];
  isCorrect: boolean;
  attemptCount: number;
}

export const ORAL_QUIZ_PROMPT_TOOLS: ChatCompletionTool[] = [
  {
    type: 'function',
    function: {
      name: 'update_progress',
      description: 'MUST be called after EVERY user answer. Tracks both individual question attempts and overall progress.',
      parameters: {
        type: 'object',
        required: ['questionSet', 'totalNumberOfQuestions'],
        properties: {
          questionSet: {
            type: 'object',
            description: 'Details about the current question set in the oral quiz.',
            required: ['questionSetId', 'numberOfQuestions', 'questions', 'isCorrect', 'attemptCount'],
            additionalProperties: false,
            strict: true,
            properties: {
              isCorrect: {
                type: 'boolean',
                description: 'Indicates if the answer was correct.',
              },
              questions: {
                type: 'array',
                items: {
                  type: 'string',
                },
                description: 'An array containing all the questions that have been answered in the current question set.',
              },
              attemptCount: {
                enum: [0, 1, 2],
                type: 'number',
                description: 'Current attempt number for this question.',
              },
              questionSetId: {
                type: 'string',
                description: 'The ID of the question set, eg. "set_1".',
              },
              numberOfQuestions: {
                enum: [0, 1, 2, 3],
                type: 'number',
                description: 'Cumulative number of questions that have been asked in the current set (max: 3).',
              },
            },
          },
          totalNumberOfQuestions: {
            type: 'number',
            description: 'Cumulative number of unique questions that have been asked in the current session (max: 30).',
          },
        },
        additionalProperties: false,
        strict: true,
      },
    },
  },
  {
    type: 'function',
    function: {
      name: 'open_premium_modal',
      description: 'Call this when the user has concluded the the free trial ',
    },
  },
];
