<nav appNavigation></nav>

<main>
  <!-- set host: { class: 'full-page-route' } on a routed page to use the full page -->
  <ion-router-outlet #outlet [swipeGesture]="false" [animation]="scaffoldContentTransitionAnimation" />
  <app-ai-assistant-button *ngIf="showAiAssistant()" />
</main>
<!-- Keep this as <div> to prevent unintended behavior in certain browser extensions -->
<div class="enigma-container" appSizeObserver data-cy="scaffold-enigma-container" (sizeChange)="onSidebarSizeChange($event)">
  <app-enigma-sidebar *ngIf="isSidebarShown()" />
</div>

<app-offline-disclaimer *ngIf="!isMobileApp" />
