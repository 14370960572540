<ui-dialog-template
  showCloseButton
  [heading]="'NAVBAR_MATERIAL_CREATION_DIALOG.HEADER' | translate"
  (closeButtonClick)="onCloseButtonClick()"
>
  <div class="content" slot="content">
    <div class="quick-action-cards">
      @for (item of quickActionCards(); track $index) {
        <ui-action-card
          compactLayout
          [heading]="item.heading | translate"
          [icon]="item.icon"
          [iconColor]="item.iconColor"
          [attr.data-cy]="item.dataCy"
          (click)="item.action('navbar_creation_dialog')"
        >
          <ng-container slot="after-heading">
            @if (item.isLocked) {
              <ui-premium-locked-pill />
            }
          </ng-container>
        </ui-action-card>
      }
    </div>

    <div class="quick-action-buttons">
      @for (item of quickActionButtons(); track $index) {
        <button uiButton tertiary [attr.data-cy]="item.dataCy" (click)="item.action()">
          <i [class]="item.icon"></i>
          <span
            >{{ item.title | translate }}
            @if (item.isLocked) {
              <ui-premium-locked-pill />
            }
          </span>
          <i class="app-icon-chevron-forward" slot="end"></i>
        </button>
      }
    </div>
  </div>
</ui-dialog-template>
