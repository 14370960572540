import { ChangeDetectionStrategy, Component, computed, Inject, input, InputSignal, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ANALYTICS_SERVICE, AnalyticsBaseService, ShareDialogOpenSource } from '@stsm/analytics';
import { AuthUrl } from '@stsm/auth/models/auth-url';
import { TranslationKey } from '@stsm/i18n/models/translation-key';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { shareIconName } from '@stsm/shared/util/platform-util';
import { IconFontName } from '@stsm/styles/icon-font';
import { BadgeComponent } from '@stsm/ui-components/badge';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { ThemedDirective } from '@stsm/ui-components/disco-color';
import { InfoCardComponent } from '@stsm/ui-components/info-card';
import { AnimatedIllustrationDirective } from '@stsm/ui-components/lottie-view/animated-illustration.directive';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { SharingBaseService } from '../../services/sharing-base.service';
import { SHARING_SERVICE } from '../../tokens/sharing-service.token';

interface SharingStep {
  icon: string;
  title: TranslationKey;
  description: TranslationKey;
}

@UntilDestroy()
@Component({
  selector: 'app-sharing-dialog',
  templateUrl: './sharing-dialog.component.html',
  styleUrls: ['./sharing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslatePipe,
    DialogTemplateComponent,
    InfoCardComponent,
    ButtonComponent,
    BadgeComponent,
    ReactiveFormsModule,
    ThemedDirective,
    IconButtonComponent,
    AnimatedIllustrationDirective,
  ],
  host: {
    ['data-cy']: 'sharing-dialog',
    '[class.referrals-reskin]': 'isReferralsReskinTreatment()',
  },
})
export class SharingDialogComponent {
  readonly source: InputSignal<ShareDialogOpenSource> = input.required();

  protected readonly isReferralsReskinTreatment: Signal<boolean> = this._sharingService.isReferralsReskinTreatmentGroup;

  protected readonly shareIconName: IconFontName = shareIconName;

  protected readonly isMobileLayout: Signal<boolean> = this._layoutStore.isMobileLayout;

  protected readonly steps: SharingStep[] = [
    {
      icon: 'app-icon-link',
      title: 'SHARE.SHARING_DIALOG.STEPS.INVITE_FRIENDS_HEADER',
      description: 'SHARE.SHARING_DIALOG.STEPS.INVITE_FRIENDS_DESC',
    },
    {
      icon: 'app-icon-glasses',
      title: 'SHARE.SHARING_DIALOG.STEPS.FRIEND_STUDIES_HEADER',
      description: 'SHARE.SHARING_DIALOG.STEPS.FRIEND_STUDIES_DESC',
    },
    {
      icon: 'app-icon-logo',
      title: 'SHARE.SHARING_DIALOG.STEPS.FREE_PREMIUM_HEADER',
      description: 'SHARE.SHARING_DIALOG.STEPS.FREE_PREMIUM_DESC',
    },
  ];

  protected readonly signupsGenerated: Signal<number> = computed(() => {
    return this._userStoreFacade.user()?.signupsGenerated ?? 0;
  });

  protected readonly sharingLink: Signal<string> = computed(() => {
    const url = new URL(`https://${this._environment.WEBAPP_URL}/${AuthUrl.GET_STARTED}`);

    url.searchParams.append('web_source', 'activeuserreferral');
    url.searchParams.append('utm_medium', this.isMobileApp ? 'mobileapp' : 'webapp');
    url.searchParams.append('utm_term', 'mailreferral');
    url.searchParams.append('ref', this._userStoreFacade.user()?.refId ?? '');

    return url.toString();
  });

  protected readonly mailtoHref: Signal<string> = computed(() => {
    if (this.isReferralsReskinTreatment()) {
      const subject = this._translationService.get('SHARE.SHARING_DIALOG.APP_TITLE_VARIANT');
      const body = this._translationService.get('SHARE.SHARING_DIALOG.APP_TEXT_VARIANT', {
        link: this.sharingLink(),
        name: this._userStoreFacade.user()?.appUser.firstName ?? '',
      });

      return `mailto:...?subject=${subject}&body=${encodeURIComponent(body)}`;
    } else {
      const subject = this._translationService.get('SHARE.SHARING_DIALOG.APP_TITLE');
      const body = this._translationService.get('SHARE.SHARING_DIALOG.APP_TEXT');
      const sharingLink = this.sharingLink();

      return `mailto:...?subject=${subject}&body=${body}%0A%0A${encodeURIComponent(sharingLink)}`;
    }
  });

  constructor(
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(SHARING_SERVICE) private readonly _sharingService: SharingBaseService,
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _translationService: TranslationService,
    private readonly _toastService: ToastService,
    private readonly _layoutStore: LayoutStore,
    private readonly _dialogRef: DialogRef,
  ) {}

  protected onClose(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'close',
        source: this.source(),
      },
    });

    this._dialogRef.dismiss();
  }

  protected copyShareLink(): void {
    void navigator.clipboard.writeText(this.sharingLink());
    this._toastService.successToast('SHARE.LINK_COPIED');
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'copy_link',
        source: this.source(),
      },
    });
  }

  protected shareViaMail(): void {
    this.trackShareViaEmail();
    window.location.href = this.mailtoHref();
  }

  protected trackShareViaEmail(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'share_via_email',
        source: this.source(),
      },
    });
  }

  protected shareApp(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'invite_friends',
        source: this.source(),
      },
    });

    this._sharingService.nativeShare({
      title: this._translationService.get('PROFILE.INVITE_TITLE'),
      text: this._translationService.get('PROFILE.INVITE_MESSAGE'),
      url: this.sharingLink(),
      source: this.source(),
    });
  }
}
