import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable, of, take } from 'rxjs';

import { ScaffoldStore } from '@stsm/scaffold/scaffold-store.service';
import { Tab } from '@stsm/shared/enums/tab';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';

/**
 * Necessary to make it possible to route to Tab.JOBS without caring for the current layout
 */
export const jobsPageGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);

  if (inject(ScaffoldStore).enableCreationCtaInNavigationBar()) {
    return of(true);
  } else {
    return inject(LayoutStore).isMobileLayout$.pipe(
      take(1),
      map((isMobileLayout: boolean) => !isMobileLayout || router.parseUrl(`${Tab.PROFILE}?profile-tab=jobs`)),
    );
  }
};
