import { Injectable } from '@angular/core';

import { DurationInMilliseconds } from '@stsm/date/enums/duration-in-milliseconds';
import { calculateAge } from '@stsm/date/functions/calculate-age';
import { createSafeDate } from '@stsm/date/functions/safe-date';
import {
  COUNTRY_ID_AUSTRIA,
  COUNTRY_ID_CANADA,
  COUNTRY_ID_FRANCE,
  COUNTRY_ID_GERMANY,
  COUNTRY_ID_SPAIN,
  COUNTRY_ID_SWITZERLAND,
  COUNTRY_ID_UK,
  COUNTRY_ID_US,
} from '@stsm/shared/models/country-ids';
import { UserGroup } from '@stsm/user/models/user-group';

@Injectable({
  providedIn: 'root',
})
export class SurveyUtilService {
  static getAppUsage(signUpDate: string): string {
    const timeDiff = new Date().getTime() - createSafeDate(signUpDate).getTime();

    if (timeDiff < DurationInMilliseconds.DAY) {
      return 'I just started today.';
    }

    if (timeDiff < DurationInMilliseconds.WEEK) {
      return 'Up to one week.';
    }

    if (timeDiff < DurationInMilliseconds.MONTH_30_DAYS) {
      return 'Up to one month.';
    }

    if (timeDiff < 4 * DurationInMilliseconds.MONTH_30_DAYS) {
      return 'Up to four months.';
    }

    if (timeDiff < 6 * DurationInMilliseconds.MONTH_30_DAYS) {
      return 'Up to 6 months.';
    }

    if (timeDiff < 365 * DurationInMilliseconds.DAY) {
      return 'Up to one year.';
    }

    return 'More than one year.';
  }

  static getUserAgeRange(birthday: string): string {
    if (!birthday) {
      return 'unknown';
    }

    const userAge = calculateAge(birthday);

    if (isNaN(userAge) || userAge < 14) {
      return 'unknown';
    }

    if (userAge < 19) {
      return '14 - 18';
    }

    if (userAge < 22) {
      return '19 - 21';
    }

    if (userAge < 25) {
      return '22 - 25';
    }

    if (userAge < 29) {
      return '26 - 29';
    }

    if (userAge < 40) {
      return '30 - 40';
    }

    return '41 and older';
  }

  static getSchoolType(userGroup: number): string {
    switch (userGroup) {
      case UserGroup.PUPIL:
        return 'High School / Middle School / etc.';

      case UserGroup.STUDENT:
        return 'College / University/ etc.';

      case UserGroup.TRAINEE:
        return 'I am a Trainee.';

      case UserGroup.LANGUAGE_LEARNER:
        return 'Language learner';

      case UserGroup.TEACHER:
        return 'Teacher';

      case UserGroup.OTHER:
      default:
        return 'unknown';
    }
  }

  static getCountry(countryId: number): string {
    switch (countryId) {
      case COUNTRY_ID_AUSTRIA:
        return 'Austria';
      case COUNTRY_ID_GERMANY:
        return 'Germany';
      case COUNTRY_ID_SWITZERLAND:
        return 'Switzerland';
      case COUNTRY_ID_US:
        return 'USA';
      case COUNTRY_ID_UK:
        return 'United Kingdom';
      case COUNTRY_ID_SPAIN:
        return 'Spain';
      case COUNTRY_ID_CANADA:
        return 'Canada';
      case COUNTRY_ID_FRANCE:
        return 'France';
      default:
        return countryId.toString();
    }
  }

  static getAgreeToDisagreeRating(rating: number): string {
    const scale: string[] = ['Strongly disagree', 'Disagree', 'Neither agree nor disagree', 'Agree', 'Strongly agree'];

    return scale[rating] ?? 'unknown';
  }
}
