@if (showCreationCtaInNavigationBar()) {
  <ion-backdrop tappable class="custom-backdrop" [class.show]="showBackdrop()" (ionBackdropTap)="onHideBackdrop()" />
  <app-navigation-menu
    [class.show-sub-menu]="showSubMenu()"
    [visibleNavigationRoutes]="subNavigationRoutes()"
    [currentTab]="currentTab()"
    [indicators]="indicators()"
    [isFullPageRouteActive]="isFullPageRouteActive()"
    (navItemClicked)="onNavItemClicked($event)"
  />
}
<a class="logo-menu-item" (click)="onLogoClick()">
  <img alt="" [src]="logo$ | async" />
</a>

<div class="items-stack items">
  @if (showCreationCtaInNavigationBar()) {
    <button uiIconButton size="lg" data-cy="navigation-material-creation-cta" (click)="openMaterialCreationDialog()">
      <i class="app-icon-add"></i>
    </button>
  }

  @for (item of showCreationCtaInNavigationBar() ? mainNavigationRoutes() : visibleNavigationRoutes(); track item.path) {
    @let itemOrder = $index + 1;

    <a [attr.data-cy]="'tabs-button-' + [item.path]" [style.order]="itemOrder" (click)="onNavItemClicked(item.path)">
      <ui-toolbar-item
        [icon]="currentTab() === item.path ? item.data.appIconActive : item.data.appIcon"
        [label]="item.data.textTranslationKey | translate"
        [isActive]="currentTab() === item.path && !isFullPageRouteActive()"
        [showUnseenIndicator]="indicators()?.[item.path]"
        [flat]="isMobileLayout()"
      />
    </a>
  }

  @if (showCreationCtaInNavigationBar()) {
    <a class="more-menu" [attr.data-cy]="'tabs-button-menu'" (click)="onHamburgerMenuClick()">
      <ui-toolbar-item
        icon="app-icon-burger-menu"
        [label]="'GLOBAL.HAMBURGER_MENU' | translate"
        [isActive]="isMoreTabActive()"
        [showUnseenIndicator]="showIndicatorOnMoreTab()"
        [flat]="isMobileLayout()"
      />
    </a>
  }
</div>

<div class="items-stack actions">
  <ui-toolbar-item
    *ngIf="!isMobileApp"
    icon="app-icon-help"
    data-cy="tabs-button-help"
    [label]="'GLOBAL.NAVIGATION_ACTIONS.HELP' | translate"
    (click)="openFeedbackModal()"
  />
  @if (isReferralsReskinTreatment()) {
    <ui-toolbar-item
      icon="app-icon-gift"
      data-cy="tabs-button-invite-friends"
      [label]="'GLOBAL.INVITE' | translate"
      (click)="onInviteFriendsClick()"
    />
  } @else {
    <ui-toolbar-item
      icon="app-icon-referral"
      data-cy="tabs-button-invite-friends"
      [label]="'GLOBAL.REFERRAL' | translate"
      (click)="onInviteFriendsClick()"
    />
  }
</div>
