import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, Inject, Signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import type { Observable } from 'rxjs';
import { of, switchMap } from 'rxjs';

import type { NavbarCreationDialogTrackingSource } from '@stsm/analytics';
import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import type { ContentDestinationChange } from '@stsm/flashcards/types/content-destination-change';
import type { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import type { TranslationKey } from '@stsm/i18n/models/translation-key';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { PremiumLimitsService } from '@stsm/premium/services/premium-limits.service';
import { PremiumModalService } from '@stsm/premium/services/premium-modal.service';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/tokes/premium-modal-service.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { CreateEditStudysetDialogService } from '@stsm/studysets/feature/components/create-edit-studyset-dialog/create-edit-studyset-dialog.service';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import type { IconFontName } from '@stsm/styles/icon-font';
import { ActionCardComponent } from '@stsm/ui-components/action-card/action-card.component';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PremiumLockedPillComponent } from '@stsm/ui-components/premium-locked-pill';
import { isPupil } from '@stsm/user/models/util/user-util';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { AiAssistantQuickAction } from '../../models/quick-actions';
import { AiAssistantPremiumService } from '../../services/ai-assistant-premium.service';
import { AiAssistantStore } from '../../services/ai-assistant-store.service';
import { AiAssistantStudysetSelectionService } from '../../services/ai-assistant-studyset-selection.service';
import { AiAssistantTrackingService } from '../../services/ai-assistant-tracking.service';
import { AiAssistantDashboardStoreService } from '../dashboard/ai-assistant-dashboard/ai-assistant-dashboard-store.service';

interface QuickActionButton {
  readonly title: TranslationKey;
  readonly icon: IconFontName;
  readonly action: () => void;
  readonly isLocked?: boolean;
  readonly dataCy: string;
}

type NavbarMaterialCreationDialogActions =
  | 'generate_flashcards'
  | 'create_studyplan'
  | 'homework_helper'
  | 'understand_topic'
  | 'create_studyset'
  | 'create_flashcards_manually'
  | 'close';

@UntilDestroy()
@Component({
  selector: 'app-navbar-material-creation-dialog',
  templateUrl: './navbar-material-creation-dialog.component.html',
  styleUrl: './navbar-material-creation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DialogTemplateComponent, TranslatePipe, ActionCardComponent, ButtonComponent, PremiumLockedPillComponent],
  host: {
    'data-cy': 'navbar-material-creation-dialog',
  },
})
export class NavbarMaterialCreationDialogComponent implements OnInit {
  protected readonly isPupil: Signal<boolean> = computed(() => isPupil(this._userStoreFacade.user()));
  protected readonly messageLimitReached: Signal<boolean> = this._aiAssistantPremiumService.vm.messageLimitReached;
  protected readonly imageLimitReached: Signal<boolean> = this._aiAssistantPremiumService.vm.imageLimitReached;
  protected readonly hasReachedStudysetCreationPremiumLimit: Signal<boolean> =
    this._premiumLimitsService.hasReachedStudysetCreationPremiumLimit;

  protected readonly quickActionCards: Signal<AiAssistantQuickAction[]> = computed(() => {
    const quickActionCards: AiAssistantQuickAction[] = [
      {
        heading: 'AI_DASHBOARD.QUICK_ACTION.GENERATE_FLASHCARDS.HEADING',
        iconColor: 'violet',
        icon: 'app-icon-ai-flashcards-subtle',
        action: (): void => {
          this._trackActionAndCloseDialog('generate_flashcards');

          this._aiAssistantStore.sendUserMessage(
            this._translationService.get('AI_DASHBOARD.QUICK_ACTION.GENERATE_FLASHCARDS.USER_MESSAGE'),
            false,
          );

          this._aiAssistantTrackingService.trackNoncountableMessageSent('quick-action');
          void this._navigationService.navigateToAssistantChatPage('navbar_generate_flashcards');
        },
        isVisible: true,
        dataCy: 'navbar-material-creation-dialog-generate-flashcards-with-ai-button',
      },
      {
        heading: 'AI_DASHBOARD.QUICK_ACTION.HOMEWORK_HELPER.HEADING',
        iconColor: 'orange',
        icon: 'app-icon-camera',
        limitBadge: 'imageUpload',
        action: (): void => {
          this._trackActionAndCloseDialog('homework_helper');

          if (this.messageLimitReached()) {
            return this._premiumModalService.openPremiumModal({
              source: 'navbar_homework_helper',
            });
          }

          if (this.isMobileApp) {
            void this._aiAssistantDashboardStoreService.handleImageUploadAction('navbar_homework_helper');
          } else {
            this._aiAssistantStore.sendUserMessage(
              this._translationService.get('AI_DASHBOARD.QUICK_ACTION.HOMEWORK_HELPER.USER_MESSAGE'),
              false,
            );

            this._aiAssistantTrackingService.trackNoncountableMessageSent('quick-action');
            void this._navigationService.navigateToAssistantChatPage('navbar_homework_helper');
          }
        },
        isVisible: true,
        isLocked: this.imageLimitReached(),
        dataCy: 'navbar-material-creation-dialog-homework-helper-button',
      },
      {
        heading: 'AI_DASHBOARD.QUICK_ACTION.CREATE_STUDYPLAN.HEADING',
        iconColor: 'green',
        icon: 'app-icon-subject',
        action: (): void => {
          this._trackActionAndCloseDialog('create_studyplan');

          if (this.hasReachedStudysetCreationPremiumLimit()) {
            return this._premiumModalService.openPremiumModal({
              source: 'navbar_create_studyplan',
            });
          }

          void this._navigationService.navigateToCreateStudyplanPage('navbar_create_studyplan');
        },
        isVisible: true,
        isLocked: this.hasReachedStudysetCreationPremiumLimit(),
        dataCy: 'navbar-material-creation-dialog-create-studyplan-button',
      },
      {
        heading: 'AI_DASHBOARD.QUICK_ACTION.UNDERSTAND_TOPIC.HEADING',
        iconColor: 'mint',
        icon: 'app-icon-lightbulb',
        action: (): void => {
          this._trackActionAndCloseDialog('understand_topic');

          if (this.messageLimitReached()) {
            return this._premiumModalService.openPremiumModal({
              source: 'navbar_understand_topic',
            });
          }

          this._aiAssistantStore.sendUserMessage(
            this._translationService.get('AI_DASHBOARD.QUICK_ACTION.UNDERSTAND_TOPIC.USER_MESSAGE'),
            false,
          );

          this._aiAssistantTrackingService.trackNoncountableMessageSent('quick-action');
          void this._navigationService.navigateToAssistantChatPage('navbar_understand_topic');
        },
        isVisible: true,
        isLocked: this.messageLimitReached(),
        dataCy: 'navbar-material-creation-dialog-understand-topic-button',
      },
    ];

    return quickActionCards.filter((quickActionCard: AiAssistantQuickAction) => quickActionCard.isVisible);
  });

  protected readonly quickActionButtons: Signal<QuickActionButton[]> = computed((): QuickActionButton[] => {
    return [
      {
        title: 'NAVBAR_MATERIAL_CREATION_DIALOG.QUICK_ACTION_BUTTON.CREATE_STUDY_SET',
        icon: 'app-icon-folder',
        action: (): void => {
          this._trackActionAndCloseDialog('create_studyset');

          if (this._premiumLimitsService.hasReachedStudysetCreationPremiumLimit()) {
            return this._premiumModalService.openPremiumModal({
              source: 'navbar_create_studyplan',
            });
          }

          void this._createEditStudysetDialogService.openDialogAndOpenStudyset({
            trackingSource: this._trackingSource,
            studysetOpenTrackingSource: this._trackingSource,
          });
        },
        isLocked: this.hasReachedStudysetCreationPremiumLimit(),
        dataCy: 'navbar-material-creation-dialog-create-studyset-button',
      },
      {
        title: 'NAVBAR_MATERIAL_CREATION_DIALOG.QUICK_ACTION_BUTTON.CREATE_FLASHCARDS_MANUALLY',
        icon: 'app-icon-add-fc',
        action: (): void => {
          this._trackActionAndCloseDialog('create_flashcards_manually');

          this._selectStudyset()
            .pipe(
              switchMap((studyset: Studyset | undefined) => {
                if (isNil(studyset)) {
                  return of(undefined);
                }

                return this._navigationService.navigateToCreateFlashcard(studyset.id, { trackingSource: this._trackingSource });
              }),
              untilDestroyed(this),
            )
            .subscribe();
        },
        dataCy: 'navbar-material-creation-dialog-create-flashcards-manually-button',
      },
    ];
  });

  private readonly _trackingSource: NavbarCreationDialogTrackingSource = 'navbar_creation_dialog';

  constructor(
    @Inject(PREMIUM_MODAL_SERVICE) private readonly _premiumModalService: PremiumModalService,
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    private readonly _aiAssistantStudysetSelectionService: AiAssistantStudysetSelectionService,
    private readonly _aiAssistantDashboardStoreService: AiAssistantDashboardStoreService,
    private readonly _createEditStudysetDialogService: CreateEditStudysetDialogService,
    private readonly _aiAssistantPremiumService: AiAssistantPremiumService,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    private readonly _premiumLimitsService: PremiumLimitsService,
    private readonly _translationService: TranslationService,
    private readonly _aiAssistantStore: AiAssistantStore,
    private readonly _aiAssistantTrackingService: AiAssistantTrackingService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _dialogRef: DialogRef,
  ) {}

  ngOnInit(): void {
    this._analyticsService.trackEvent({
      action: 'navbar_creation_dialog_open',
    });
  }

  protected onCloseButtonClick(): void {
    this._trackActionAndCloseDialog('close');
  }

  private _trackActionAndCloseDialog(action: NavbarMaterialCreationDialogActions): void {
    this._analyticsService.trackEvent({
      action: 'navbar_creation_dialog_interact',
      properties: {
        action,
      },
    });

    // Prevent dismissing if an action is blocked or triggers a modal
    const shouldDismiss = !(
      action === 'create_flashcards_manually' ||
      (action === 'create_studyplan' && this.hasReachedStudysetCreationPremiumLimit()) ||
      (action === 'homework_helper' && this.messageLimitReached()) ||
      (action === 'understand_topic' && this.messageLimitReached()) ||
      (action === 'create_studyset' && this._premiumLimitsService.hasReachedStudysetCreationPremiumLimit())
    );

    if (shouldDismiss) {
      this._dialogRef.dismiss();
    }
  }

  private _selectStudyset(): Observable<Studyset | undefined> {
    return this._aiAssistantStudysetSelectionService
      .openDialog({
        trackingSource: this._trackingSource,
      })
      .pipe(
        switchMap((contentDestination: ContentDestinationChange | undefined) => {
          if (!contentDestination) {
            return of(undefined);
          }

          return this._studysetsStoreFacade.studysetById(contentDestination.destination.studysetId);
        }),
      );
  }
}
