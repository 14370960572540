import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  Inject,
  OnInit,
  Signal,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { floor } from 'lodash-es';
import { debounceTime, distinctUntilChanged, map, Observable, switchMap, take, tap } from 'rxjs';

import { GenericChatGptService } from '@stsm/global/composite/services/generic-chat-gpt.service';
import { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { oralQuizQuestionSetCount } from '@stsm/global/composite/services/oral-quiz-prompt';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { FULL_PAGE_ROUTE_CLASS_NAME } from '@stsm/shared/constants/full-page-route-class-name';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { MarkdownToHtmlPipe } from '@stsm/shared/pipes/markdown-to-html/markdown-to-html.pipe';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { Id } from '@stsm/shared/types/id';
import { filterUndefined, shareReplayRefCount } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsBaseService } from '@stsm/studysets/services/studysets-base.service';
import { STUDYSETS_SERVICE } from '@stsm/studysets/services/tokens/studysets-service.token';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { AiAssistantAvatarComponent } from '@stsm/ui-components/ai-assistant-avatar/ai-assistant-avatar.component';
import { AvatarInput } from '@stsm/ui-components/ai-assistant-avatar/avatar-types';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { ResponsiveButtonComponent } from '@stsm/ui-components/button/responsive-button.component';
import { ChatMessageComponent } from '@stsm/ui-components/chat/chat-message/chat-message.component';
import { StaticPageLayoutComponent } from '@stsm/ui-components/page-layout';
import { ProgressBarComponent } from '@stsm/ui-components/progress-bar';
import { SpeechBubbleComponent } from '@stsm/ui-components/speech-bubble/speech-bubble.component';

import { AiOralQuizMessage, AiOralQuizStore, AiOralQuizViewModel } from '../../services/ai-oral-quiz-store.service';
import { FlashcardsAdService } from '../../services/flashcards-ad.service';
import { ExamIntegrationStore } from '../../smart-exam/exam-integration.store';
import { QuizPageStore, QuizPageViewModel } from '../../smart-exam/quiz-page.store';

export enum TutorState {
  LISTENING = 'LISTENING',
  THINKING = 'THINKING',
}

@UntilDestroy()
@Component({
  selector: 'app-oral-quiz-page',
  standalone: true,
  imports: [
    ProgressBarComponent,
    LetDirective,
    NgIf,
    StaticPageLayoutComponent,
    ButtonComponent,
    TranslatePipe,
    IconButtonComponent,
    AiAssistantAvatarComponent,
    ResponsiveButtonComponent,
    SpeechBubbleComponent,
    MarkdownToHtmlPipe,
    ChatMessageComponent,
  ],
  templateUrl: './oral-quiz-page.component.html',
  styleUrls: ['./oral-quiz-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void <=> *', animate('.3s ease')),
    ]),
  ],
  providers: [ExamIntegrationStore, FlashcardsAdService, QuizPageStore, AiOralQuizStore],
  host: {
    class: FULL_PAGE_ROUTE_CLASS_NAME,
  },
})
export class OralQuizPageComponent implements OnInit, AfterViewInit {
  @ViewChild('audioPlayer', { static: false })
  audioPlayer?: ElementRef<HTMLAudioElement>;

  isMuted: boolean = false;
  isMobile: boolean = this._layoutStore.isMobileLayout();

  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer?: ElementRef<HTMLElement>;

  messages: Signal<AiOralQuizMessage[]> = computed(() =>
    this._oralQuizStore.messages().filter((message: AiOralQuizMessage) => !message.gptOnly),
  );

  tutorIsSpeaking: WritableSignal<boolean> = signal<boolean>(false);
  isRecording: WritableSignal<boolean> = signal(false);

  readonly quizPageVm$: Observable<QuizPageViewModel> = this._quizPageStore.viewModel$;

  protected hasReachedPremiumLimit: Signal<boolean> = this._oralQuizStore.hasReachedPremiumLimit;
  protected userButtonState: Signal<'record' | 'stopRecording' | 'processing'> = computed(() => {
    if (this.isRecording()) {
      return 'stopRecording';
    }

    if (this.vm.isGeneratingResponse()) {
      return 'processing';
    }

    return 'record';
  });

  protected lastAssistantMessage: WritableSignal<AiOralQuizMessage | undefined> = signal(undefined);

  protected lastUserMessage: Signal<AiOralQuizMessage | undefined> = computed(() => {
    const lastMessage = this.messages().pop();

    return lastMessage?.role === 'user' ? lastMessage : undefined;
  });

  protected readonly studySetName: WritableSignal<string> = signal('');

  protected readonly showScrollToBottomButton: WritableSignal<boolean> = signal(false);
  protected readonly vm: AiOralQuizViewModel = this._oralQuizStore.vm;

  protected readonly avatarSize: Signal<number> = computed(() => (this._layoutStore.isMobileLayout() ? 100 : 200));

  protected tutorState: Signal<TutorState | undefined> = computed(() => {
    if (this.isRecording()) {
      return TutorState.LISTENING;
    }

    if (this.vm.isGeneratingResponse()) {
      return TutorState.THINKING;
    }

    return undefined;
  });

  protected TutorStateEnum: typeof TutorState = TutorState;

  protected readonly questionSetTotalCount: Signal<number> = computed(() => {
    const currentSetIndex = floor((this.vm.questionIndex() - 1) / oralQuizQuestionSetCount);

    return (currentSetIndex + 1) * oralQuizQuestionSetCount;
  });

  protected readonly avatarPresetState: Signal<AvatarInput> = computed(() => {
    const state = {
      isRecording: this.isRecording(),
      tutorIsSpeaking: this.tutorIsSpeaking(),
      avatarStateFromStore: this.vm.avatarState(),
      isGeneratingResponse: this.vm.isGeneratingResponse(),
    };

    this._loggerService.debug('Avatar State Computed:', state);

    // Prioritize dynamic states over default ones
    if (this.isRecording()) {
      return AvatarInput.LISTENING; // Recording takes priority
    }

    if (this.tutorIsSpeaking()) {
      return AvatarInput.TALKING; // Speaking takes priority
    }

    const avatarStateFromStore = this.vm.avatarState();

    if (avatarStateFromStore !== AvatarInput.DEFAULT_PRESET) {
      return avatarStateFromStore; // Fallback to store-driven state (e.g., SAD, HURRAY)
    }

    if (this.vm.isGeneratingResponse()) {
      return AvatarInput.THINK; // Generating response
    }

    return AvatarInput.DEFAULT_PRESET; // Default state
  });

  private _studyset$: Observable<Studyset> | undefined;

  constructor(
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    @Inject(STUDYSETS_SERVICE) private readonly _studysetService: StudysetsBaseService,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _quizPageStore: QuizPageStore,
    private readonly _genericChatGptService: GenericChatGptService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _oralQuizStore: AiOralQuizStore,
    private readonly _layoutStore: LayoutStore,
    private readonly _loggerService: LoggerService,
  ) {
    this._oralQuizStore.messages$
      .pipe(
        debounceTime(0),
        tap(() => {
          this.scrollToBottom();
        }),
        map((messages: AiOralQuizMessage[]) =>
          messages.filter((message: AiOralQuizMessage) => message.role === 'assistant' && !message.gptOnly).pop(),
        ),
        filterUndefined(),
        distinctUntilChanged((prev: AiOralQuizMessage, curr: AiOralQuizMessage) => prev.id === curr.id),
        // Set the last assistant message (without waiting for audio)
        tap(async (lastRelevantMessage: AiOralQuizMessage) => {
          this.lastAssistantMessage.set(lastRelevantMessage);

          if (lastRelevantMessage.audioFile) {
            await this._playAudio(lastRelevantMessage.audioFile);
          }
        }),

        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnInit(): void {
    const studysetId$: Observable<Id> = this._activatedRoute.params.pipe(
      map((params: Params) => +params['studysetId']),
      shareReplayRefCount(1),
    );

    this._studyset$ = studysetId$.pipe(
      switchMap((studysetId: number) => {
        return this._studysetService
          .fetchStudysetIfNecessary(studysetId)
          .pipe(switchMap(() => this._studysetsStoreFacade.studysetById(studysetId)));
      }),
      shareReplayRefCount(1),
    );

    this._studyset$.pipe(take(1), untilDestroyed(this)).subscribe((studyset: Studyset) => {
      this.studySetName.set(studyset.name);
      void this._oralQuizStore.setStudyset(studyset);
    });
  }

  ngAfterViewInit(): void {
    if (this.audioPlayer?.nativeElement) {
      this.audioPlayer.nativeElement.muted = this.isMuted;
      this.audioPlayer.nativeElement.volume = 1.0;
    } else {
      this._loggerService.error('Audio player not found in ngAfterViewInit');
    }
  }

  goBack(studysetId?: Id): void {
    if (studysetId) {
      void this._navigationService.pop({ studysetId });
    }
  }

  async toggleRecording(): Promise<void> {
    this.audioPlayer?.nativeElement.pause();

    if (this.isRecording()) {
      await this._stopRecording();
    } else {
      await this._startRecording();
    }
  }

  sendMessage(message: string, isOptionClick: boolean = true): void {
    this._oralQuizStore.sendUserMessage(message, isOptionClick);
  }

  onOptionClick(option: string): void {
    this.sendMessage(option, false);
  }

  onScroll(event: Event): void {
    const target: HTMLDivElement = event.target as HTMLDivElement;
    const scrollThreshold = 200; // the button will appear only after the user scrolled 200px from the bottom
    const showScrollToBottomButton =
      target.scrollHeight > target.offsetHeight + scrollThreshold &&
      target.scrollTop < target.scrollHeight - target.offsetHeight - scrollThreshold;

    this.showScrollToBottomButton.set(showScrollToBottomButton);
  }

  scrollToBottom(): void {
    this.scrollContainer?.nativeElement.scrollTo({ top: this.scrollContainer?.nativeElement.scrollHeight, behavior: 'smooth' });
  }

  toggleMute(): void {
    this.isMuted = !this.isMuted;

    if (this.audioPlayer && this.audioPlayer.nativeElement) {
      this.audioPlayer.nativeElement.muted = this.isMuted;
    }
  }

  onInterrupt(): void {
    this.toggleMute();
  }

  private _playAudio(audioBlob: Blob | undefined): Promise<boolean> {
    if (!this.audioPlayer || !audioBlob) {
      this._loggerService.error('Audio player not found', this.audioPlayer, audioBlob);

      return Promise.resolve(false);
    }

    const audioUrl: string = URL.createObjectURL(audioBlob);
    const audio: HTMLAudioElement = this.audioPlayer.nativeElement;
    audio.src = audioUrl;

    return new Promise<boolean>((resolve: (value: boolean | PromiseLike<boolean>) => void): void => {
      audio.onended = (): void => {
        this.tutorIsSpeaking.set(false);
        this._changeDetectorRef.detectChanges();
        resolve(true);
      };

      audio.onpause = (): void => {
        this.tutorIsSpeaking.set(false);
        this._changeDetectorRef.detectChanges();
        resolve(true);
      };

      audio.onplaying = (): void => {
        this.tutorIsSpeaking.set(true);
        this._changeDetectorRef.detectChanges();
      };

      audio.play().catch((): void => {
        resolve(false); // In case of an error while playing the audio
      });
    });
  }

  private async _startRecording(): Promise<void> {
    const can = await VoiceRecorder.canDeviceVoiceRecord();

    if (!can.value) {
      this._loggerService.error('Cannot record voice');

      return;
    }

    let may = await VoiceRecorder.hasAudioRecordingPermission();

    if (!may.value) {
      may = await VoiceRecorder.requestAudioRecordingPermission();

      if (!may.value) {
        this._loggerService.debug('Not allow to record voice');

        return;
      }
    }

    this.isRecording.set(true);
    this._changeDetectorRef.detectChanges();
    await VoiceRecorder.startRecording();
  }

  private async _stopRecording(): Promise<void> {
    async function b64toBlob(base64: string, type: string): Promise<Blob> {
      const res = await fetch(`data:${type};base64,${base64}`);

      return await res.blob();
    }

    this.isRecording.set(false);
    this._changeDetectorRef.detectChanges();

    const result = await VoiceRecorder.stopRecording();

    const blob: Blob = await b64toBlob(result.value.recordDataBase64, result.value.mimeType);
    const file = await this._convertAudioToMono(blob);

    const resultText = await this._genericChatGptService.getSpeechToText(file, this._oralQuizStore.vm.quizLanguageCode());

    this.sendMessage(resultText, true);
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Converts the audio file to mono channel and WAV format.
   * Helper function to convert the data coming from mobile devices to a format that can be used by the backend.
   */
  private async _convertAudioToMono(file: File | Blob): Promise<Blob> {
    const audioContext = new AudioContext({ sampleRate: 16000 });
    const arrayBuffer = await file.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Create offline context for processing
    const offlineContext = new OfflineAudioContext(1, audioBuffer.length, 16000);
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineContext.destination);
    source.start();

    // Render audio
    const renderedBuffer = await offlineContext.startRendering();

    // Convert to WAV format
    const length = renderedBuffer.length * 2;
    const buffer = new ArrayBuffer(44 + length);
    const view = new DataView(buffer);

    // WAV header
    const writeString = (view: DataView, offset: number, string: string): void => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true);
    view.setUint32(24, 16000, true);
    view.setUint32(28, 32000, true);
    view.setUint16(32, 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    // Write audio data
    const data = new Float32Array(renderedBuffer.getChannelData(0));
    let offset = 44;

    for (let i = 0; i < data.length; i++) {
      const value = data[i] ?? 0;
      const sample = Math.max(-1, Math.min(1, value));
      view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
      offset += 2;
    }

    return new Blob([buffer], { type: 'audio/wav' });
  }
}
