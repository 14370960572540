import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

import { LoggerService } from '../../logger/logger.service';
import { MarkdownService } from '../../services/markdown.service';

/**
 * Transforms markdown to sanitized HTML
 *
 * Use with caution:
 * If used on already converted HTML, it removes some attributes, e.g. Froala style attributes, which led to bug CORE-387
 */
@Pipe({
  name: 'markdownToHtml',
})
export class MarkdownToHtmlPipe implements PipeTransform {
  constructor(
    private readonly _markdownService: MarkdownService,
    private readonly _loggerService: LoggerService,
  ) {}

  transform(value: string | undefined): string {
    if (isNil(value)) {
      this._loggerService.debug('markdownToHtml: value is nil, string expected');

      return '';
    }

    return this._markdownService.parse(value);
  }
}
