import { computed, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { patchState, SignalState, signalState } from '@ngrx/signals';
import { franc } from 'franc';
import { firstValueFrom, Observable, switchMap, take } from 'rxjs';

import { createSafeDate } from '@stsm/date/functions/safe-date';
import { DateFnsUtil } from '@stsm/date/util/timezone-safe-date-fns-util';
import { FlashcardsBaseService } from '@stsm/flashcards/services/flashcards-base-service';
import { FLASHCARDS_SERVICE } from '@stsm/flashcards/services/tokens/flashcards-service.token';
import { Flashcard } from '@stsm/flashcards/types/flashcard';
import { FlashcardEntry } from '@stsm/flashcards/types/flashcard-entry';
import { getDefaultFlashcardFilterSettings } from '@stsm/flashcards/types/flashcard-filter-settings';
import { FlashcardLocation } from '@stsm/flashcards/types/flashcard-location';
import { ChatGptPromptService } from '@stsm/global/composite/services/chat-gpt-prompt.service';
import { ErrorHandlingService } from '@stsm/global/composite/services/error-handling.service';
import { GenericChatGptService } from '@stsm/global/composite/services/generic-chat-gpt.service';
import { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { oralQuizQuestionSetCount } from '@stsm/global/composite/services/oral-quiz-prompt';
import { SurveyUtilService } from '@stsm/global/composite/services/survey-util.service';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import {
  ChatCompletionMessage,
  ChatCompletionMessageToolCall,
  ChatGptInput,
  ChatGptMessage,
  ChatGptRequestBody,
  CombinedChatGptMessage,
  LanguageDetectionResponse,
} from '@stsm/global/models/ai/chat-gpt.interface';
import { SUPPORTED_LANGUAGES_IN_BCP_47, SupportedLanguage } from '@stsm/i18n/models/supported-language';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { PremiumLimitsService } from '@stsm/premium/services/premium-limits.service';
import { PremiumModalService } from '@stsm/premium/services/premium-modal.service';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/tokes/premium-modal-service.token';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { TARGET_MARKET_ENV_STUDYSMARTER, TARGET_MARKET_ENV_VAIA, TargetMarketEnvironment } from '@stsm/shared/models/environment-base';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { Id } from '@stsm/shared/types/id';
import { JsonObject } from '@stsm/shared/types/json-object';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { AvatarInput } from '@stsm/ui-components/ai-assistant-avatar/avatar-types';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { getDegree } from '@stsm/user/models/degree';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserGroup } from '@stsm/user/models/user-group';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { OralQuizResultComponent } from '../components/oral-quiz-result/oral-quiz-result.component';
import { ORAL_QUIZ_PROMPT_TOOLS } from '../constants/oral-quiz-prompt-tools';

// Iso codes mapping from ISO 639-3 to ISO 639-1
const iso6393ToIso6391: Record<string, string> = {
  sqi: 'sq', // Albanian
  ara: 'ar', // Arabic
  hye: 'hy', // Armenian
  awa: '', // Awadhi (no ISO 639-1 code)
  aze: 'az', // Azerbaijani
  bak: 'ba', // Bashkir
  eus: 'eu', // Basque
  bel: 'be', // Belarusian
  ben: 'bn', // Bengali
  bos: 'bs', // Bosnian
  bul: 'bg', // Bulgarian
  cat: 'ca', // Catalan
  zho: 'zh', // Chinese
  hrv: 'hr', // Croatian
  ces: 'cs', // Czech
  dan: 'da', // Danish
  nld: 'nl', // Dutch
  eng: 'en', // English
  est: 'et', // Estonian
  fao: 'fo', // Faroese
  fin: 'fi', // Finnish
  fra: 'fr', // French
  glg: 'gl', // Galician
  kat: 'ka', // Georgian
  deu: 'de', // German
  ell: 'el', // Greek
  guj: 'gu', // Gujarati
  hin: 'hi', // Hindi
  hun: 'hu', // Hungarian
  ind: 'id', // Indonesian
  gle: 'ga', // Irish
  ita: 'it', // Italian
  jpn: 'ja', // Japanese
  jav: 'jv', // Javanese
  kan: 'kn', // Kannada
  kas: 'ks', // Kashmiri
  kaz: 'kk', // Kazakh
  kor: 'ko', // Korean
  kir: 'ky', // Kyrgyz
  lav: 'lv', // Latvian
  lit: 'lt', // Lithuanian
  mkd: 'mk', // Macedonian
  msa: 'ms', // Malay
  mlt: 'mt', // Maltese
  cmn: 'zh', // Mandarin
  mar: 'mr', // Marathi
  mol: 'ro', // Moldovan
  mon: 'mn', // Mongolian
  nep: 'ne', // Nepali
  nob: 'no', // Norwegian
  ori: 'or', // Oriya
  pus: 'ps', // Pashto
  fas: 'fa', // Persian (Farsi)
  pol: 'pl', // Polish
  por: 'pt', // Portuguese
  pan: 'pa', // Punjabi
  ron: 'ro', // Romanian
  rus: 'ru', // Russian
  san: 'sa', // Sanskrit
  srp: 'sr', // Serbian
  snd: 'sd', // Sindhi
  sin: 'si', // Sinhala
  slk: 'sk', // Slovak
  slv: 'sl', // Slovenian
  spa: 'es', // Spanish
  ukr: 'uk', // Ukrainian
  urd: 'ur', // Urdu
  uzb: 'uz', // Uzbek
  vie: 'vi', // Vietnamese
  cym: 'cy', // Welsh
};

interface MessageProperties {
  text: string;
  userOptions: string[];
  responseInputPlaceholder?: string;
}

export interface AiOralQuizMessage extends CombinedChatGptMessage {
  id: Id;
  text: string;
  userOptions?: string[];
  gptOnly?: boolean;
  audioFile?: Blob | undefined;
  template?: string;
  values?: JsonObject;
}

export interface AiOralQuizState {
  messages: AiOralQuizMessage[];
  isGeneratingResponse: boolean;
  currentStudyset: Studyset | undefined;
  hasSeenWelcomeMessage: boolean;
  gptModelVersion: 3 | 4; // for now always 3, but keeping the base logic for potential future changes
  questionIndex: number;
  quizLanguageCode: string;
  avatarState: AvatarInput;
}

export interface AiOralQuizViewModel {
  isGeneratingResponse: Signal<boolean>;
  userOptions: Signal<string[]>;
  responseInputPlaceholder: Signal<string>;
  questionIndex: Signal<number>;
  quizLanguageCode: Signal<string>;
  avatarState: Signal<AvatarInput>;
}

const initialAiOralQuizState: AiOralQuizState = {
  messages: [],
  isGeneratingResponse: false,
  currentStudyset: undefined,
  hasSeenWelcomeMessage: false,
  gptModelVersion: 4,
  questionIndex: 0,
  quizLanguageCode: 'en',
  avatarState: AvatarInput.DEFAULT_PRESET,
};

@Injectable()
export class AiOralQuizStore {
  readonly messages$: Observable<AiOralQuizMessage[]>;
  readonly messages: Signal<AiOralQuizMessage[]> = computed(() => this._state.messages());
  readonly hasReachedPremiumLimit: Signal<boolean> = this._premiumLimitsService.hasReachedOralQuizModePremiumLimit;
  readonly vm: AiOralQuizViewModel = {
    isGeneratingResponse: computed(() => this._state.isGeneratingResponse()),
    userOptions: computed(() => this.messages().slice(-1)[0]?.userOptions ?? []),
    responseInputPlaceholder: signal(''),
    questionIndex: computed(() => this._state.questionIndex()),
    quizLanguageCode: computed(() => this._state.quizLanguageCode()),
    avatarState: computed(() => this._state.avatarState()),
  };

  private readonly _state: SignalState<AiOralQuizState> = signalState<AiOralQuizState>(initialAiOralQuizState);
  private readonly _nextMessageId: Signal<number> = computed(() => {
    return this.messages().length + 1;
  });

  private readonly _totalNumberOfQuestions: WritableSignal<number> = signal(0);

  constructor(
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    @Inject(PREMIUM_MODAL_SERVICE) private readonly _premiumModalService: PremiumModalService,
    @Inject(FLASHCARDS_SERVICE) private readonly _flashcardsService: FlashcardsBaseService,
    private readonly _genericChatGptService: GenericChatGptService,
    private readonly _loggerService: LoggerService,
    private readonly _sentryService: SentryService,
    private readonly _translationService: TranslationService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _premiumLimitsService: PremiumLimitsService,
    private readonly _errorHandlingService: ErrorHandlingService,
    private readonly _chatGptPromptService: ChatGptPromptService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _targetMarketProvider: TargetMarketProvider,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
  ) {
    this.messages$ = toObservable(this.messages);
  }

  async setStudyset(studyset: Studyset): Promise<void> {
    const systemPromptProperties = await this._getSystemPromptProperties('', studyset);
    const message = this._getAiAssistantChatGptInput(systemPromptProperties);
    this._loggerService.debug('Starting conversation with studyset:', studyset, 'and message:', message);
    this.startConversation(studyset, message);
  }

  startConversation(studyset: Studyset, systemPromptInput: ChatGptInput): void {
    const systemPromptMessage: AiOralQuizMessage = {
      ...systemPromptInput,
      id: this._nextMessageId(),
      text: '',
      content: null,
      gptOnly: true,
    };

    patchState(this._state, { messages: [], currentStudyset: studyset });
    const isToolCallRequired = false;
    console.warn('startConversation calls _handleMessageRequest', systemPromptMessage);
    void this._handleMessageRequest([systemPromptMessage], isToolCallRequired);
  }

  /**
   * Show a system chat message that is not sent to gpt
   * @param message The message that will be displayed in the chat
   */
  sendInternalSystemMessage(message: string): Id {
    const id: Id = this._nextMessageId();

    const messageTemplate: AiOralQuizMessage = {
      id,
      role: 'system',
      text: message,
      content: message,
    };

    patchState(this._state, { messages: [...this._state.messages(), messageTemplate] });

    return id;
  }

  /**
   * Send a message to gpt with the role 'user'
   * @param message The message that will be sent to gpt
   * @param isOptionClick Whether the message is sent by clicking on an option
   */
  sendUserMessage(message: string, isOptionClick: boolean): void {
    const messageTemplate: AiOralQuizMessage = {
      id: this._nextMessageId(),
      role: 'user',
      text: message,
      content: message,
    };
    this._loggerService.warn('sendUserMessage calls _handleMessageRequest', messageTemplate);
    void this._handleMessageRequest([messageTemplate], isOptionClick);
  }

  private async _handleMessageRequest(newMessages: AiOralQuizMessage[], isToolCallRequired: boolean = false): Promise<void> {
    this._loggerService.warn('_handleMessageRequest', newMessages, 'isToolCallRequired', isToolCallRequired);

    patchState(this._state, () => ({
      messages: [...this._state.messages(), ...newMessages],
      isGeneratingResponse: true,
    }));

    try {
      const responseFormat: JsonObject = {
        type: 'object',
        properties: {
          text: { type: 'string' },
          userOptions: {
            type: 'array',
            items: { type: 'string' },
            description:
              'Optional list of actions the user can take, provided in the detected language. If not relevant, list is empty. Do not offer the answer as an item. Do not offer "Upgrade to Premium" if the user is premium.',
          },
          metadata: {
            type: 'object',
            properties: {
              detected_language: { type: 'string' },
              totalNumberOfQuestions: { type: 'number', description: 'Cumulative questions in the current session (max: 30)' },
            },
            required: ['detected_language', 'totalNumberOfQuestions'],
            additionalProperties: false,
            strict: true,
          },
        },
        required: ['text', 'metadata', 'userOptions'],
        additionalProperties: false,
      };

      const gptRequest$ = this._genericChatGptService.getChatCompletions({
        messages: this.messages().map(
          (message: AiOralQuizMessage): ChatGptInput =>
            ({
              role: message.role,
              content: message.content,
              tool_call_id: message.tool_call_id,
              tool_calls: message.tool_calls,
              template: message.template,
              values: message.values,
            }) as ChatGptInput,
        ),
        model: this._state.gptModelVersion() === 4 ? '4o' : '4o-mini',
        feature: 'oral-quiz',
        json_mode: true,
        tools: ORAL_QUIZ_PROMPT_TOOLS,
        tool_choice: isToolCallRequired ? 'required' : 'auto',
        json_schema: responseFormat,
      });

      const response: ChatCompletionMessage[] = await firstValueFrom(gptRequest$);

      if (!response || response.length === 0) {
        throw new Error('Empty response from ChatGPT');
      }

      const responseMessage = response[0];
      this._loggerService.warn('responseMessage', responseMessage);

      if (responseMessage?.tool_calls?.length) {
        void this._handleToolCalls(responseMessage);

        return;
      }

      if (!responseMessage?.content) {
        throw new Error('Invalid response content from ChatGPT');
      }

      const parsedMessage = this._parseChatMessage(responseMessage, this._nextMessageId());

      if (!parsedMessage) {
        throw new Error('Failed to parse chat message');
      }

      if (parsedMessage.text) {
        try {
          // Try to fetch the audio if parsedMessage.text exists
          const audioFile: Blob = await this._genericChatGptService.getAudioForText(parsedMessage.text);

          const finalMessage: AiOralQuizMessage = {
            ...parsedMessage,
            audioFile,
          };

          patchState(this._state, {
            messages: [...this._state.messages(), finalMessage],
          });
        } catch (audioError) {
          // Log and handle audio-specific errors without interrupting the flow
          this._loggerService.error('[Oral Quiz] Error fetching audio file:', audioError);
          this._errorHandlingService.handleError({
            error: audioError,
            messageToDisplay: this._translationService.get('ORAL_QUIZ.PAGE.AUDIO_ERROR.GETTING_AUDIO'),
            useToast: true,
            sentryMessage: '[Oral Quiz]: Failed to fetch audio file',
          });
        }
      }
    } catch (error) {
      this._loggerService.error('[Oral Quiz] Error during message request:', error);
      this._errorHandlingService.handleError({
        error,
        messageToDisplay: 'ORAL_QUIZ.PAGE.MESSAGE_REQUEST_ERROR',
        useToast: true,
        sentryMessage: '[Oral Quiz]: An Error occurred during message request',
      });

      patchState(this._state, { isGeneratingResponse: false });
      this.sendInternalSystemMessage(this._translationService.get('AI_ASSISTANT.CHAT.SYSTEM.ERROR.RETRY'));
    } finally {
      patchState(this._state, { isGeneratingResponse: false });
    }
  }

  private _parseChatMessage(message: ChatCompletionMessage, id: number): AiOralQuizMessage | undefined {
    let parsedContent: MessageProperties;

    if (message?.content) {
      try {
        parsedContent = JSON.parse(message.content);
        const chatMessage: AiOralQuizMessage = {
          id,
          role: 'assistant',
          content: message.content,
          ...parsedContent,
        };

        this._loggerService.debug('[AI Chat] parsed chat message:', chatMessage);

        return chatMessage;
      } catch (error) {
        this._sentryService.reportToSentry('Error parsing assistant chat message', error);
        this.sendInternalSystemMessage(this._translationService.get('AI_ASSISTANT.CHAT.SYSTEM.ERROR.RETRY'));

        return undefined;
      }
    } else {
      return undefined;
    }
  }

  private async _handleToolCalls(responseMessage: ChatCompletionMessage): Promise<void> {
    if (!responseMessage.tool_calls?.length) {
      return;
    }

    this._loggerService.debug('Processing tool calls:', responseMessage.tool_calls);

    patchState(this._state, (state: AiOralQuizState) => {
      return {
        ...state,
        isGeneratingResponse: false,
        messages: [
          ...state.messages,
          {
            ...responseMessage,
            id: this._nextMessageId(),
            text: '',
            gptOnly: true,
          },
        ],
      };
    });

    // Process tool calls in order
    for (const toolCall of responseMessage.tool_calls) {
      try {
        const toolResultMessage = this._processToolCall(toolCall);

        if (!toolCall.id) {
          throw new Error(`Missing tool_call_id for tool: ${toolCall.function.name}`);
        }

        const acknowledgmentMessage = this._getToolCallMessage(toolResultMessage, toolCall.id);
        this._loggerService.debug('Acknowledgment message:', acknowledgmentMessage);

        this._loggerService.warn('_handleToolCalls calls _handleMessageRequest', acknowledgmentMessage);
        await this._handleMessageRequest([acknowledgmentMessage], false);
        this._triggerFinalEvaluation(toolCall);
      } catch (error) {
        this._loggerService.error('Error processing tool call:', toolCall.function.name, error);

        const errorMessage = this._getToolCallMessage(`Error processing ${toolCall.function.name}`, toolCall.id || 'unknown');
        this._loggerService.debug('Error acknowledgment message:', errorMessage);

        this._loggerService.warn('_handleToolCalls calls _handleMessageRequest in catch', errorMessage);
        await this._handleMessageRequest([errorMessage], false);
      }
    }
  }

  private _handlePremiumModal(): void {
    try {
      this._premiumModalService.openPremiumModal({
        source: 'oral-quiz',
      });
    } catch (error) {
      this._sentryService.reportToSentry('Error parsing premium modal tool call', error);
    }
  }

  private _getToolCallMessage(toolResponse: string, toolId: string): AiOralQuizMessage {
    return {
      id: this._nextMessageId(),
      role: 'tool',
      text: toolResponse, // Sent back to GPT
      content: toolResponse, // Ensure 'content' is populated
      tool_call_id: toolId, // Correctly map to the tool call ID
      gptOnly: true, // Ensure it's GPT-specific
    };
  }

  private _processToolCall(toolCall: ChatCompletionMessageToolCall): string {
    this._loggerService.debug('Processing tool call:', toolCall.function.name, toolCall.function.arguments);

    if (!toolCall.function.arguments) {
      const error = `Missing arguments for tool call: ${toolCall.function.name}`;
      this._loggerService.error(error);

      return error; // Ensure a message is returned for missing arguments
    }

    try {
      switch (toolCall.function.name) {
        case 'update_progress': {
          const { questionSet, totalNumberOfQuestions } = JSON.parse(toolCall.function.arguments);
          const isCorrect = questionSet.isCorrect;
          const attemptCount = questionSet.attemptCount;

          if (isCorrect || attemptCount === 2) {
            patchState(this._state, (state: AiOralQuizState) => ({
              ...state,
              questionIndex: state.questionIndex + 1,
              avatarState: isCorrect ? AvatarInput.HURRAY : AvatarInput.SAD,
              questionSet,
              totalNumberOfQuestions,
            }));
          } else {
            patchState(this._state, (state: AiOralQuizState) => ({
              ...state,
              avatarState: AvatarInput.SAD,
            }));
          }

          setTimeout(() => {
            patchState(this._state, (state: AiOralQuizState) => ({
              ...state,
              avatarState: AvatarInput.DEFAULT_PRESET,
            }));
          }, 1500);

          return 'Progress updated successfully';
        }
        case 'open_premium_modal': {
          this._handlePremiumModal();

          return 'Premium modal opened successfully';
        }

        default: {
          const warning = `Unhandled tool call: ${toolCall.function.name}`;
          this._loggerService.warn(warning);

          return warning;
        }
      }
    } catch (error) {
      this._loggerService.error('Error processing tool call:', toolCall.function.name, error);

      return `Error processing ${toolCall.function.name}`;
    }
  }

  private _getOralQuizEvaluationInput(): ChatGptMessage {
    return this._chatGptPromptService.getChatGptInput({
      template: 'oral_quiz_user_evaluation_template',
      values: {},
      role: 'system',
    }) as ChatGptMessage;
  }

  private _triggerFinalEvaluation(toolCall: ChatCompletionMessageToolCall): void {
    if (toolCall.function.name === 'update_progress') {
      const { totalNumberOfQuestions } = JSON.parse(toolCall.function.arguments);
      const isCorrect = JSON.parse(toolCall.function.arguments).questionSet.isCorrect;
      const attemptCount = JSON.parse(toolCall.function.arguments).questionSet.attemptCount;
      const numberOfQuestionsInSet = JSON.parse(toolCall.function.arguments).questionSet.numberOfQuestions;

      this._totalNumberOfQuestions.set(totalNumberOfQuestions);

      if (totalNumberOfQuestions % 3 === 0 && numberOfQuestionsInSet === 3 && (isCorrect || attemptCount === 2)) {
        const messagesForEvaluation: ChatGptMessage[] = this.messages() as ChatGptMessage[];
        const message = this._getOralQuizEvaluationInput();
        const messagesWithEvaluation: ChatGptMessage[] = [...messagesForEvaluation, message];

        const responseBody: ChatGptRequestBody = {
          messages: messagesWithEvaluation,
          feature: 'oral-quiz-evaluation',
          model: '4o-mini',
          json_mode: true,
          json_schema: {
            type: 'object',
            properties: {
              evaluation: {
                type: 'string',
                description: "Give a detailed evaluation of the user's performance.",
              },
              score: {
                type: 'number',
                description: 'The score the user has achieved in percent.',
              },
            },
            required: ['evaluation', 'score'],
            additionalProperties: false,
          },
          tool_choice: 'auto',
        };

        this._genericChatGptService
          .getFinalEvaluation(responseBody)
          .then(async (chatCompletionMessages: ChatCompletionMessage[]) => {
            this._loggerService.debug('Final evaluation:', chatCompletionMessages);
            const content = chatCompletionMessages[0]!.content as string;

            if (!content) {
              throw new Error('No content in backend response.');
            }

            const { evaluation, score } = JSON.parse(content) as {
              evaluation?: string;
              score?: string;
            };

            const ref = this._platformModalService.create({
              component: OralQuizResultComponent,
              data: { evaluation, percentageScore: score },
              mobileOptions: { isAutoHeight: true },
              webOptions: { maxWidth: '600px' },
            });

            const dialogResult = await firstValueFrom(ref.afterClosed());

            if (dialogResult !== 'continue') {
              void this._navigationService.pop({ studysetId: this._state.currentStudyset()?.id });
            }
          })
          .catch((error: Error) => {
            this._loggerService.error('Error in final evaluation:', error);
          });
      }
    } else {
      this._loggerService.warn('Final evaluation not triggered for tool call:', toolCall.function.name);
    }
  }

  private async _getSystemPromptProperties(
    currentLocation: string,
    studyset: Studyset,
  ): Promise<{
    glossary: string;
    flashcardContent: string;
    userStatus: string;
    userAttributes: string;
    companyName: string;
    studysetName: string;
    daysSinceJoined: string;
    currentLocation: string;
    numberOfQuestionsPerSet: string;
    quizLanguageCode: string;
    totalNumberOfQuestions: string;
  }> {
    const targetMarket = this._targetMarketProvider.targetMarket();
    const targetMarketEnv: TargetMarketEnvironment = targetMarket === 'core' ? TARGET_MARKET_ENV_STUDYSMARTER : TARGET_MARKET_ENV_VAIA;

    const flashcardCountRequirement = 5; // TODO: adjust when functions are added that have no flashcard requirement
    const { universityName, userGroup, appUser, countryId, schoolTypeName, degree, classLevel, language, studyFieldName } =
      await firstValueFrom(this._userStoreFacade.user$);

    const studysets: Studyset[] = await firstValueFrom(this._studysetsStoreFacade.libraryStudysets$);
    const recentlyUsedStudysets: Partial<Studyset>[] = studysets
      .filter((studyset: Studyset) => studyset.flashcardCount >= flashcardCountRequirement)
      .sort((a: Studyset, b: Studyset) => {
        return createSafeDate(b.lastUsed).getTime() - createSafeDate(a.lastUsed).getTime();
      })
      .slice(0, 5)
      .map((studyset: Studyset) => {
        return {
          name: studyset.name,
          flashcard_count: studyset.flashcardCount,
          document_count: studyset.slidesetCount,
          notes_count: studyset.summaryCount,
          id: studyset.id,
        };
      });

    let userAttributes: Record<string, string | number> = {
      // user_group: USER_GROUP_TO_AMPLITUDE_NAMES_MAPPING[userGroup],
      country: countryId ? SurveyUtilService.getCountry(countryId) : '-',
      name: appUser.firstName,
      language: SUPPORTED_LANGUAGES_IN_BCP_47[language as SupportedLanguage],
    };

    let isPremium = 'free';
    this._userStoreFacade.premiumInfo$
      .pipe(
        take(1),
        switchMap((premiumInfo: PremiumInfo) => {
          isPremium = premiumInfo.isPremium ? 'premium' : 'free';

          return VOID;
        }),
      )
      .subscribe();

    if (userGroup === UserGroup.STUDENT) {
      userAttributes = {
        ...userAttributes,
        degree: getDegree(degree),
        studyfield: studyFieldName,
        university: universityName,
      };
    }

    if (userGroup === UserGroup.PUPIL) {
      userAttributes = {
        ...userAttributes,
        class_level: classLevel,
        school_type: schoolTypeName,
      };
    }

    const daysSinceJoined = Math.abs(DateFnsUtil.differenceInCalendarDays(createSafeDate(appUser.dateJoined ?? new Date()))).toString();
    const currentLocationText = this._getLocationString(currentLocation, studyset);
    const flashcardContent = await this._getFlashcardContent(studyset.id);

    // Detect the language of flashcard content
    const finalLangCode = await this._decideFinalLanguage(flashcardContent);

    patchState(this._state, (state: AiOralQuizState) => ({
      ...state,
      quizLanguageCode: finalLangCode,
    }));

    this._loggerService.debug('[AI Chat] system prompt options:', {
      companyName: targetMarketEnv.COMPANY_NAME,
      userAttributes,
      currentLocation: currentLocationText,
      daysSinceJoined,
      studysets: recentlyUsedStudysets,
      userStatus: isPremium.toString(),
      glossary: this._getGlossaryString(),
      flashcardContent,
      studysetName: studyset?.name ?? '',
      numberOfQuestionsPerSet: oralQuizQuestionSetCount.toString(),
      quizLanguageCode: finalLangCode,
      totalNumberOfQuestions: this._totalNumberOfQuestions().toString(),
    });

    return {
      companyName: targetMarketEnv.COMPANY_NAME,
      userAttributes: JSON.stringify(userAttributes),
      currentLocation: currentLocationText,
      daysSinceJoined,
      userStatus: isPremium.toString(),
      glossary: this._getGlossaryString(),
      flashcardContent,
      studysetName: studyset?.name ?? '',
      numberOfQuestionsPerSet: oralQuizQuestionSetCount.toString(),
      quizLanguageCode: finalLangCode,
      totalNumberOfQuestions: this._totalNumberOfQuestions().toString(),
    };
  }

  private _getAiAssistantChatGptInput(values: {
    glossary: string;
    flashcardContent: string;
    userStatus: string;
    userAttributes: string;
    companyName: string;
    studysetName: string;
    daysSinceJoined: string;
    currentLocation: string;
    numberOfQuestionsPerSet: string;
    quizLanguageCode: string;
    totalNumberOfQuestions: string;
  }): ChatGptInput {
    return this._chatGptPromptService.getChatGptInput({
      template: 'oral_quiz_template',
      values,
      role: 'system',
    });
  }

  private _getOralQuizLanguageDetectionInput(): ChatGptMessage {
    return this._chatGptPromptService.getChatGptInput({
      template: 'oral_quiz_language_detection_template',
      values: {},
      role: 'system',
    }) as ChatGptMessage;
  }

  private _getLocationString(currentLocation: string, studyset?: Studyset): string {
    const baseText = `The user is currently on the following page: "${currentLocation}"`;

    return baseText + this._getStudysetText(studyset);
  }

  private _getGlossaryString(): string {
    if (this._translationService.currentLanguage() === 'de') {
      return `
          ### German Language ###
          When communicating in German, refer to the user as "Du", not as "Sie". And use the following glossary for German:
          Flashcards - Karteikarten
          Notes - Notizen
          Studyset - Lernset
          Files - Dokumente
          Library - Bibliothek
      `;
    }

    return '';
  }

  private _getStudysetText(studyset?: Studyset): string {
    if (!studyset) {
      return '';
    }

    return `inside the study set "${studyset.name}" (ID: ${studyset.id})`;
  }

  private async _getFlashcardContent(studysetId: Id): Promise<string> {
    const flashcardsChunk = await firstValueFrom(
      this._flashcardsService.getFlashcardsChunk(studysetId, getDefaultFlashcardFilterSettings(FlashcardLocation.QUIZ, 30), {
        quantity: 30,
      }),
    );
    const flashcards = flashcardsChunk.flashcards;
    const flashcardsCombinedTextContent = flashcards
      .map((flashcard: Flashcard) => this._getCombinedFlashcardTextContent(flashcard))
      .join('\n');

    this._loggerService.debug('[Oral Quiz] Flashcards content:', flashcardsCombinedTextContent);

    return flashcardsCombinedTextContent;
  }

  private _getCombinedFlashcardTextContent(flashcard: Flashcard): string {
    // Combine flashcard question and correct answers
    const content = `Q: ${flashcard.questionFlashcardEntry.text}; A: ${flashcard.answerFlashcardEntries
      .filter((entry: FlashcardEntry) => entry.isCorrect)
      .map((entry: FlashcardEntry) => entry.text)
      .join(', ')}`;

    // Clean the content: remove HTML tags, normalize whitespace, and trim
    return content
      .replace(/<[^>]+>/g, '') // Remove HTML tags
      .replace(/\s+/g, ' ') // Normalize whitespace
      .trim();
  }

  /**
   * Detects the language of the given content with the franc library.
   * @param content - The text content to analyze
   * @private
   */
  private _detectLanguageFromContent(content: string): string {
    // Step 1: Split content into smaller chunks (e.g., sentences)
    const chunks: string[] = content.split(/[.?!;]+/).filter((chunk: string) => chunk.trim().length > 0);

    // Step 2: Detect the language of each chunk
    const languageCounts: Record<string, number> = {};

    for (const chunk of chunks) {
      const detectedLanguageIso6393: string = franc(chunk, { minLength: 10 }); // Analyze each chunk

      if (!languageCounts[detectedLanguageIso6393]) {
        languageCounts[detectedLanguageIso6393] = 0;
      }
      languageCounts[detectedLanguageIso6393]++;
    }

    // Step 3: Determine the predominant language
    let predominantLanguageIso6393: string | undefined = undefined;
    let maxCount: number = 0;

    for (const [language, count] of Object.entries(languageCounts)) {
      if (count > maxCount) {
        predominantLanguageIso6393 = language;
        maxCount = count;
      }
    }
    // Step 4: Convert ISO 639-3 to ISO 639-1
    const languageCode: string =
      predominantLanguageIso6393 !== undefined ? (iso6393ToIso6391[predominantLanguageIso6393] ?? 'unknown') : 'unknown';

    this._loggerService.warn(
      'Predominant language:',
      predominantLanguageIso6393,
      'Counts:',
      languageCounts,
      'Language code:',
      languageCode,
    );

    return languageCode;
  }

  /**
   * Decides the final language by comparing local detection with backend detection for the given content.
   * If both agree, the local detection is returned. If one is unknown, the other is returned.
   * If both are different, the backend detection is returned.
   * Helps to avoid false positives and negatives in language detection.
   *
   * @param {string} content - The flashcards text content to analyze
   * @returns {Promise<string>} The final decided language code
   */
  private async _decideFinalLanguage(content: string): Promise<string> {
    const localLangCode: string = this._detectLanguageFromContent(content);
    let backendLangCode = 'unknown';

    const systemMessage = this._getOralQuizLanguageDetectionInput();
    const userMessage: ChatGptMessage = {
      role: 'user',
      content: `Text Content:\n${content}`,
    };

    const responseBody: ChatGptRequestBody = {
      messages: [systemMessage, userMessage],
      model: '4o-mini',
      feature: 'language-identification',
      json_mode: true,
      json_schema: {
        type: 'object',
        properties: {
          languageCode: { type: 'string', description: 'Detected language code (ISO 639-1)' },
          explanation: { type: 'string', description: 'Reasoning behind the detection' },
        },
        required: ['languageCode', 'explanation'],
        additionalProperties: false,
      },
      tool_choice: 'auto',
    };

    try {
      const backendResponse: LanguageDetectionResponse = await this._genericChatGptService.detectLanguage(responseBody);
      const rawContent = backendResponse.choices?.[0]?.message?.content ?? '';

      if (!rawContent) {
        throw new Error('No content in backend response.');
      }

      const parsed = JSON.parse(rawContent) as {
        languageCode?: string;
        explanation?: string;
      };

      if (!parsed.languageCode) {
        throw new Error('Missing "languageCode" in backend JSON');
      }

      backendLangCode = parsed.languageCode.trim() || 'unknown';

      this._loggerService.debug('[Oral Quiz] Explanation from backend detection:', parsed.explanation);
    } catch (error) {
      this._loggerService.error('[Oral Quiz] Failed to detect language from backend:', error);
    }

    // Compare local and backend results
    if (localLangCode !== 'unknown' && localLangCode === backendLangCode) {
      return localLangCode;
    } else if (localLangCode === 'unknown') {
      return backendLangCode;
    } else if (backendLangCode === 'unknown') {
      return localLangCode;
    } else {
      this._loggerService.warn('[Oral Quiz] Mismatch in language detection:', {
        localLangCode,
        backendLangCode,
      });

      return backendLangCode;
    }
  }
}
