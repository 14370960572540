@if (isReferralsReskinTreatment()) {
  <div class="dialog-wrapper">
    <button
      uiIconButton
      secondary
      size="xs"
      class="close-button"
      data-cy="sharing-dialog-close-button"
      [attr.aria-label]="'GLOBAL.CLOSE' | translate"
      (click)="onClose()"
    >
      <i class="app-icon-close"></i>
    </button>
    <div class="header">
      <div class="icon-wrapper">
        <i uiAnimatedIllustration name="referrals-icon"></i>
      </div>
      <h3 data-cy="sharing-dialog-heading">{{ "SHARE.SHARING_DIALOG.INVITE_FRIENDS_HEADER" | translate }}</h3>
    </div>
    <div class="content">
      <h4 class="sub-title">{{ "SHARE.SHARING_DIALOG.HOW_IT_WORKS" | translate }}</h4>

      <div class="steps-wrapper">
        @for (step of steps; track step.title; let last = $last) {
          <div class="step">
            <div class="stepper-path">
              <div class="icon-wrapper">
                <i [class]="step.icon"></i>
              </div>
              @if (!last) {
                <div class="line"></div>
              }
            </div>
            <div class="step-content">
              <h5>{{ step.title | translate }}</h5>
              <p>{{ step.description | translate }}</p>
            </div>
          </div>
        }
      </div>
      <span class="terms-conditions">{{ "SHARE.SHARING_DIALOG.TERMS_CONDITIONS_NOTE" | translate }}</span>
    </div>
  </div>

  @if (isMobileApp) {
    <div class="buttons-container mobile-container">
      <button uiButton size="lg" primary data-cy="sharing-dialog-mobile-cta" (click)="shareApp()">
        <i slot="icon" [class]="shareIconName"></i>{{ "SHARE.SHARING_DIALOG.INVITE_FRIENDS" | translate }}
      </button>
    </div>
  } @else {
    <div class="buttons-container">
      <a uiButton secondary data-cy="sharing-dialog-share-via-email-button" [href]="mailtoHref()" (click)="trackShareViaEmail()">
        <i class="app-icon-mail" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.SHARE_EMAIL_BUTTON" | translate }}
      </a>

      <button uiButton secondary data-cy="sharing-dialog-copy-link-button" (click)="copyShareLink()">
        <i class="app-icon-link" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.COPY_LINK_BUTTON" | translate }}
      </button>
    </div>
  }
} @else {
  <ui-dialog-template showCloseButton uiThemed colorId="denim" (closeButtonClick)="onClose()">
    <div class="header" slot="header">
      <h4>{{ "SHARE.SHARING_DIALOG.HEADER" | translate }}</h4>
    </div>

    <div class="content" slot="content">
      <div class="benefits">
        <ui-info-card hasIconBackground icon="app-icon-follow" [isCenteredLayout]="!isMobileLayout()">
          <ng-container slot="header">{{ "SHARE.SHARING_DIALOG.MOTIVATE_HEADER" | translate }}</ng-container>
          <ng-container slot="description">{{ "SHARE.SHARING_DIALOG.MOTIVATE_DESC" | translate }}</ng-container>
        </ui-info-card>

        <!-- Swap/Switch icon missing from icon-font -->
        <ui-info-card hasIconBackground icon="app-icon-group" [isCenteredLayout]="!isMobileLayout()">
          <ng-container slot="header">{{ "SHARE.SHARING_DIALOG.PERSPECTIVES_HEADER" | translate }}</ng-container>
          <ng-container slot="description">{{ "SHARE.SHARING_DIALOG.PERSPECTIVES_DESC" | translate }}</ng-container>
        </ui-info-card>

        <ui-info-card hasIconBackground icon="app-icon-share-android" [isCenteredLayout]="!isMobileLayout()">
          <ng-container slot="header">{{ "SHARE.SHARING_DIALOG.MATERIALS_HEADER" | translate }}</ng-container>
          <ng-container slot="description">{{ "SHARE.SHARING_DIALOG.MATERIALS_DESC" | translate }}</ng-container>
        </ui-info-card>
      </div>

      <div class="actions">
        <div class="friends-referred">
          <div class="info">
            <div class="text">{{ "SHARE.SHARING_DIALOG.FRIENDS_REFERRED" | translate }}</div>
            <ui-badge>{{ signupsGenerated() }}</ui-badge>
          </div>
          <div class="description">{{ "SHARE.SHARING_DIALOG.FRIENDS_REFERRED_DESC" | translate }}</div>
        </div>

        <div class="buttons">
          <button uiButton secondary (click)="shareViaMail()">
            <i class="app-icon-mail" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.SHARE_EMAIL_BUTTON" | translate }}
          </button>

          <button uiButton (click)="copyShareLink()">
            <i class="app-icon-link" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.COPY_LINK_BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </ui-dialog-template>
}
