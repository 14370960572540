import { Injectable } from '@angular/core';

import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { NavbarMaterialCreationDialogComponent } from './navbar-material-creation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class NavbarMaterialCreationDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  openMaterialCreationDialog(): void {
    this._platformModalService.create({
      component: NavbarMaterialCreationDialogComponent,
      webOptions: {
        width: '500px',
        maxWidth: '80vw',
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }
}
