import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, Input, input, InputSignal } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { IconFontName } from '@stsm/styles/icon-font';

@Component({
  selector: 'ui-toolbar-item',
  templateUrl: './toolbar-item.component.html',
  styleUrls: ['./toolbar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslatePipe, NgClass],
  host: { '[class.in-menu]': 'inMenu()' },
})
export class ToolbarItemComponent {
  @Input({ required: true }) icon!: IconFontName;
  @Input({ required: true }) label: string = '';
  inMenu: InputSignal<boolean> = input(false);

  @HostBinding('class.active')
  @Input({ transform: booleanAttribute })
  isActive: boolean = false;

  @Input({ transform: booleanAttribute })
  @HostBinding('class.flat')
  flat: boolean = false;

  @Input({ transform: booleanAttribute })
  showUnseenIndicator: boolean = false;
}
