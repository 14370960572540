import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, Signal, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { FeedbackModalService } from '@stsm/global/composite/components/feedback-form/feedback-modal.service';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { PremiumLimitsService } from '@stsm/premium/services/premium-limits.service';
import { PremiumModalService } from '@stsm/premium/services/premium-modal.service';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/tokes/premium-modal-service.token';
import { MarkdownToHtmlPipe } from '@stsm/shared/pipes/markdown-to-html/markdown-to-html.pipe';
import { Studyset } from '@stsm/studysets/models/studyset';
import { ButtonComponent } from '@stsm/ui-components/button';
import { CircleProgressbarComponent } from '@stsm/ui-components/circle-progressbar';
import { ConfettiCanvasComponent } from '@stsm/ui-components/confetti-canvas';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { FlashcardResultPresentationComponent } from '../flashcard-result-presentation/flashcard-result-presentation.component';

@UntilDestroy()
@Component({
  selector: 'app-oral-quiz-result',
  templateUrl: './oral-quiz-result.component.html',
  styleUrls: ['./oral-quiz-result.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    CircleProgressbarComponent,
    ConfettiCanvasComponent,
    FlashcardResultPresentationComponent,
    TranslatePipe,
    MarkdownToHtmlPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OralQuizResultComponent implements AfterViewInit {
  @ViewChild(ConfettiCanvasComponent) confettiCanvas?: ConfettiCanvasComponent;

  @Input() percentageScore: number = 0;
  @Input() evaluation: string = '';
  @Input() studyset!: Studyset;

  protected readonly hasReachedPremiumLimit: Signal<boolean> = this._premiumLimitsService.hasReachedOralQuizModePremiumLimit;

  constructor(
    @Inject(PREMIUM_MODAL_SERVICE) private readonly _premiumModalService: PremiumModalService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _feedbackModalService: FeedbackModalService,
    private readonly _premiumLimitsService: PremiumLimitsService,
  ) {}

  ngAfterViewInit(): void {
    if (this.percentageScore > 0) {
      setTimeout(() => {
        void this.confettiCanvas?.triggerConfetti({ particleCount: this.percentageScore });
      }, 300);
    }
  }

  onButtonClick(): void {
    if (this.hasReachedPremiumLimit()) {
      this._premiumModalService.openPremiumModal({
        source: 'oral-quiz',
      });

      return;
    }

    this._platformModalService.dismiss('continue');
  }

  onClose(): void {
    this._platformModalService.dismiss();
  }

  onClickGiveFeedback(): void {
    this._feedbackModalService.showFeedbackModal({
      subject: '[Oral Quiz Feedback]',
      eventSource: 'oral-quiz',
    });
  }
}
