import { inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { defer, map } from 'rxjs';

import { AbTestService, ExperimentFlag, ShareDialogOpenSource, VariantData } from '@stsm/analytics';

export abstract class SharingBaseService {
  private readonly _abTestService: AbTestService = inject(AbTestService);

  private _isReferralsReskinExposureEventTriggered: boolean = false;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly isReferralsReskinTreatmentGroup: Signal<boolean> = toSignal(
    defer(() => this._abTestService.getExperimentValue(ExperimentFlag.REFERRALS_RESKIN)).pipe(
      map(({ value }: VariantData) => value === 'treatment'),
    ),
    { initialValue: false },
  );

  copyToClipboard(content: string): Promise<void> {
    return navigator.clipboard.writeText(content);
  }

  triggerReferralsReskinExposureEvent(): void {
    if (this._isReferralsReskinExposureEventTriggered) {
      return;
    }

    return void this._abTestService
      .trackCustomExposure({
        flag: ExperimentFlag.REFERRALS_RESKIN,
        eventAction: 'exposure_referrals_reskin',
      })
      .then(() => {
        this._isReferralsReskinExposureEventTriggered = true;
      });
  }

  abstract shareApp(options: { source: ShareDialogOpenSource }): void;

  /**
   * Uses the native sharing dialog for mobile to share a file
   * Automatically downloads a file on Web.
   *
   * Returns a Promise which results to true if the user shared (or downloaded) the file or false in case the user
   * cancelled the sharing prompt.
   *
   * @param params params
   * @param params.blob the file blob
   * @param params.fileName the fileName
   */
  abstract shareOrDownloadFile(params: { blob: Blob; fileName: string }): Promise<boolean>;

  abstract nativeShare(options: { text: string; title: string; url: string; source: ShareDialogOpenSource }): void;
}
